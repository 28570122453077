import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { NewBrandLP } from "../../components/NewBrandLP"
import Layout from "../../components/Layout/Layout"
import { Helmet } from "react-helmet"
import "../../styles/lp_global.scss"
import getPageData from "../../analytics/getPageData"
import { useScrollTracking } from "../../analytics/useScrollTracking"
import { usePageTracking } from "../../analytics/usePageTracking"
export const query = graphql`
  query {
    brandLogoBig: file(
      relativePath: {
        eq: "landing-page-new/de/top-menu-section/brand-logo.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED
          height: 42
          quality: 100
          placeholder: NONE
        )
      }
    }
    brandLogoSmall: file(
      relativePath: {
        eq: "landing-page-new/de/top-menu-section/brand-logo.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED
          height: 32
          quality: 100
          placeholder: NONE
        )
      }
    }
    pageHeadMediaDesktop: file(
      relativePath: {
        eq: "landing-page-new/de/page-head-section/desktop-media.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 445)
      }
    }
    pageHeadMediaMobile: file(
      relativePath: {
        eq: "landing-page-new/de/page-head-section/mobile-media.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 690)
      }
    }
    partnersLogo1: file(
      relativePath: { eq: "landing-page-new/de/partners-section/frame-1.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED
          height: 106
          quality: 100
          placeholder: NONE
        )
      }
    }
    partnersLogo2: file(
      relativePath: { eq: "landing-page-new/de/partners-section/frame-2.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED
          height: 106
          quality: 100
          placeholder: NONE
        )
      }
    }
    partnersLogo3: file(
      relativePath: { eq: "landing-page-new/de/partners-section/frame-3.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED
          height: 106
          quality: 100
          placeholder: NONE
        )
      }
    }
    partnersLogo4: file(
      relativePath: { eq: "landing-page-new/de/partners-section/frame-4.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED
          height: 106
          quality: 100
          placeholder: NONE
        )
      }
    }
    partnersLogo5: file(
      relativePath: { eq: "landing-page-new/de/partners-section/frame-5.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED
          height: 106
          quality: 100
          placeholder: NONE
        )
      }
    }
    videoPlaceholder: file(
      relativePath: {
        eq: "landing-page-new/de/demo-video-section/preview-image.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 1060
          height: 544
          quality: 100
        )
      }
    }
    presentationItem1media: file(
      relativePath: {
        eq: "landing-page-new/de/presentation-items-section/media-1.svg"
      }
    ) {
      publicURL
    }
    presentationItem2media: file(
      relativePath: {
        eq: "landing-page-new/de/presentation-items-section/media-2.svg"
      }
    ) {
      publicURL
    }
    presentationItem3media: file(
      relativePath: {
        eq: "landing-page-new/de/presentation-items-section/media-3.svg"
      }
    ) {
      publicURL
    }
    videoTestimonialAuthorThumb: file(
      relativePath: {
        eq: "landing-page-new/de/video-testimonial-section/author-thumb.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 60)
      }
    }
    imageFeaturesMedia1: file(
      relativePath: {
        eq: "landing-page-new/de/image-features-section/media-1.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED
          height: 100
          quality: 100
          placeholder: NONE
        )
      }
    }
    imageFeaturesMedia2: file(
      relativePath: {
        eq: "landing-page-new/de/image-features-section/media-2.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED
          height: 100
          quality: 100
          placeholder: NONE
        )
      }
    }
    imageFeaturesMedia3: file(
      relativePath: {
        eq: "landing-page-new/de/image-features-section/media-3.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED
          height: 100
          quality: 100
          placeholder: NONE
        )
      }
    }
    gettingStartedMedia: file(
      relativePath: {
        eq: "landing-page-new/de/getting-started-section/getting-started.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`

const LogicielGestionFinanciere = props => {
  useScrollTracking()
  usePageTracking()

  const videoSectionHtmlId = "watchVideo"
  const formSectionHtmlId = "demoForm"

  // extract query data
  const {
    videoPlaceholder: { childImageSharp: previewImage },
    partnersLogo1,
    partnersLogo2,
    partnersLogo3,
    partnersLogo4,
    partnersLogo5,
    partnersLogo6,
    partnersLogo7,
    presentationItem1media,
    presentationItem2media,
    presentationItem3media,
    videoTestimonialAuthorThumb,
    imageFeaturesMedia1,
    imageFeaturesMedia2,
    imageFeaturesMedia3,
    gettingStartedMedia,
    brandLogoBig: { childImageSharp: logoBig },
    brandLogoSmall: { childImageSharp: logoSmall },
    pageHeadMediaDesktop,
    pageHeadMediaMobile
  } = props.data

  // top menu section (header)
  const topMenuSection = {
    brandLogo: {
      ...logoBig,
      description: "Agicap logo",
      small: logoSmall
    },
    brandLogoTargetUrl: "https://agicap.com/de/",
    rightMenuItems: [
      {
        id: "e48fc062-2708-55f2-b1be-1f861526eb70",
        title: "Kostenlos testen",
        subtitle: null,
        targetUrl: `#${formSectionHtmlId}`,
        relAttribute: null,
        targetAttribute: null,
        displayStyle: "button",
        backgroundColor: null,
        eventAction: "click_request_trial",
        eventLabel: "{{page_perimeter}}",
        icon: null,
        primaryColumnMenuItemIcon: null,
        ctaImage: null,
        ctaTitle: null,
        ctaLink: null,
        childMenuItems: null
      }
    ],
    locale: "de-DE",
    defaultLocale: "de-DE"
  }

  // page head section
  const pageHeadSection = {
    desktopMedia: pageHeadMediaDesktop,
    mobileMedia: pageHeadMediaMobile,
    mediaLinkTargetUrl: `#${videoSectionHtmlId}`,
    mediaLinkLabel: null,
    title:
      'Erstellen Sie einen <span class="highlight">Finanzierungsplan</span> mit Agicap',
    subtitle:
      "Mit Agicap können Sie sich einen eigenen, individuellen Finanzierungsplan erstellen, die die Liquiditätshistorie Ihres Geschäfts berücksichtigt. Die Historie dient Ihnen als Vorlage für einen neuen Finanzierungsplan, den Sie auch exportieren können.",
    body: "Jetzt kostenlos gemeinsam mit einem:einer unserer Expert:innen testen",
    ctaButtons: [
      {
        title: "Demo anfordern",
        targetUrl: `#${formSectionHtmlId}`,
        eventAction: "click_request_demo",
        eventLabel: "{{page_perimeter}}",
        displayStyle: "primaryButton"
      },
      {
        title: "Ins Tool schauen",
        targetUrl: `#${videoSectionHtmlId}`,
        eventAction: "click_request_video_demo",
        eventLabel: "{{page_perimeter}}",
        displayStyle: "secondaryButton"
      }
    ],
    ctaFooter:
      "<ul><li>Rückruf innerhalb von einer Stunde</li><li>Keine Verpflichtung</li></ul>",
    hubspotForm: {
      formId: "6adbfd71-20b8-47de-988c-6478906a8bfd",
      portalId: "2856475",
      locale: "de-DE",
      eventAction: "LeadGen Form Submission"
    },
    features: [
      { title: "N°1", description: "in Europa" },
      { title: "+6.000", description: "Kunden" },
      { title: "+ 300", description: "Kompatible<br/>Integrationen" },
      { title: "4,6/5", description: "Kundenzufriedenheit" }
    ],
    locale: "de-DE",
    defaultLocale: "de-DE"
  }

  // partners section
  const partnersSection = {
    title: 'Diese Kunden <span class="highlight">vertrauen uns</span>',
    logos: [
      partnersLogo1,
      partnersLogo2,
      partnersLogo3,
      partnersLogo4,
      partnersLogo5,
      partnersLogo6,
      partnersLogo7
    ],
    features: []
  }

  // demo youtubevideo section
  const demoVideoSection = {
    title: 'Entdecken Sie Agicap in <span class="highlight">3 Minuten!</span>',
    hubspotForm: {
      portalId: "2856475",
      formId: "fc0573b7-68d1-4edd-9414-f934f2aa2087",
      locale: "de-DE"
    },
    youtubeVideo: {
      youtubeVideoId: "bUn21qV550Y",
      previewImage
    },
    htmlId: videoSectionHtmlId
  }

  // presentation items section
  const presentationItemsSection = {
    sectionTitle: "Die Finanzplanungssoftware für Ihr Unternehmen",
    presentationItems: [
      {
        title: "Finanzplanung ohne Excel dank automatisierter Software",
        media: presentationItem1media,
        body: "<ul><li>Vertrauen Sie bei Ihrer Finanzplanung nicht auf fehleranfällige Excel-Tabellen.</li><li>Verknüpfen Sie stattdessen ihre Bankkonten und vorhandenen Tools für Ihre Buchhaltung und Rechnungsabwicklung mit Agicap für eine Echtzeit-Ansicht der Liquidität des Unternehmens.</li><li>Rechnungsabwicklung mit Agicap für eine Echtzeit-Ansicht der Liquidität des Unternehmens. Verfolgen Sie Ihre Liquidität tagesaktuell durch eine automatisierte Finanzplanung.</li></ul>",
        ctaLabel: "Mehr erfahren",
        ctaTargetUrl: `#${formSectionHtmlId}`
      },
      {
        title:
          "Ergänzen Sie Ihren Finanzplan mit zuverlässigen Liquiditätsprognosen",
        media: presentationItem2media,
        body: "<ul><li>Anhand verschiedener Cashflow-Szenarien simulieren Sie einfach die finanziellen Auswirkungen auf Ihre Liquidität durch Veränderungen wie Umsatzrückgang oder Kurzarbeit.</li><li>Sie erhalten dynamische Liquiditätsprognosen für die nächsten Monate oder sogar Jahre und können auf Grundlage dessen Ihre Finanzplanung anpassen und die richtigen, monetären Entscheidungen treffen.</li></ul>",
        ctaLabel: "Mehr erfahren",
        ctaTargetUrl: `#${formSectionHtmlId}`
      },
      {
        title: "Verwalten Sie Ihre Ausgaben im Finanzplan effizienter",
        media: presentationItem3media,
        body: "<ul><li>Sie erhalten einen Echtzeit-Überblick der Ein- und Auszahlungen Ihres Unternehmens in unserem Finanzplan-Tool.</li><li>So können Sie analysieren, was Sie für jeden Posten ausgeben und sind in der Lage, Ihre Ausgaben effizienter zu verwalten.</li></ul>",
        ctaLabel: "Mehr erfahren",
        ctaTargetUrl: `#${formSectionHtmlId}`
      }
    ]
  }

  // global demo form section
  const demoFormSection = {
    title:
      'Jetzt unverbindlich und kostenfrei mit einem:einer unserer <span class="highlight">Expert:innen testen</span>',
    hubspotForm: {
      portalId: "2856475",
      // formId: "70a52e88-8842-49e6-83f1-30f669312270",
      formId: "56e894e7-27b1-47c0-8d37-a4c0f862ffac",
      eventAction: "LeadGen Form Submission",
      eventLabel: "d4d53fba-3e14-4dbe-8350-45b414fe5b4e-DemoForm",
      locale: "de-DE"
    },
    htmlId: formSectionHtmlId
  }

  // features section
  const featuresSection = {
    features: [
      {
        title: "Gesicherte Schnittstellen",
        description:
          "100 % gesicherte Daten: Die Sicherheit Ihrer Daten liegt uns am Herzen",
        icon: "security"
      },
      {
        title: "Unverbindlich",
        description:
          "Probieren Sie Agicap kostenfrei und unverbindlich aus. Sie benötigen keine Kreditkarten, um durchzustarten",
        icon: "flash"
      },
      {
        title: "Wir sind für Sie da",
        description:
          "Unser Onboarding- und Support-Team unterstützt Sie zuverlässig bei der Einrichtung & Nutzung von Agicap",
        icon: "chat"
      }
    ]
  }

  // boxed features
  const boxedFeatures = {
    sectionTitle: "Die Finanzplanungssoftware für Ihr Unternehmen",
    features: [
      {
        icon: "number-1",
        title:
          "Wir rufen Sie binnen einer Stunde zurück (Werktags von 09:00 Uhr bis 18:00 Uhr)",
        description:
          "Um Ihre Fragen zu beantworten und Bedürfnisse zu besprechen"
      },
      {
        icon: "number-2",
        title: "Gemeinsames Aufsetzen Ihres Testaccounts",
        description:
          "Kein Hinterlegen von Zahlungsinformationen erforderlich. Testen Sie Agicap in weniger als 20 Minuten"
      },
      {
        icon: "number-3",
        title: "Wir unterstützen Sie bei jedem Schritt",
        description:
          "Unser Expertenteam unterstützt Sie in der Einrichtung und mit kostenlosen Anwender-Schulungen"
      }
    ]
  }

  // video testimonial section
  const videoTestimonialSection = {
    youtubeVideo: {
      youtubeVideoId: "Pvq3bONBKEA",
      previewImage: null
    },
    testimonial: {
      body: "Ich glaube, dass die Liquidität für jedes eCommerce Unternehmen das A und O ist.",
      author: "Johannes Kliesch,",
      position: "Gründer und Geschäftsführer bei SNOCKS",
      authorThumb: videoTestimonialAuthorThumb
    }
  }

  // getting started section
  const gettingStartedSection = {
    media: gettingStartedMedia,
    title: "Starten Sie jetzt!",
    ctaLabel: "Kostenlos testen",
    ctaTargetUrl: `#${formSectionHtmlId}`
  }

  const imageFeatures = {
    features: [
      {
        media: imageFeaturesMedia1,
        title: "ISO 27001",
        body: "Datensicherheit durch unseren Partner FinTecSystems. Ihre Kundendaten liegen in deutschem Rechenzentrum zertifiziert nach ISO27001."
      },
      {
        media: imageFeaturesMedia2,
        title: "PSD2-Zertifizierung durch die BaFin",
        body: "Wir und unser Partner agieren DSGVO konform. Wir agieren nach dem deutschen Bundesdatenschutzgesetz sowie dem EU-Datenschutz (DSGVO)."
      },
      {
        media: imageFeaturesMedia3,
        title: "SSL-verschlüsselte Datenübertragung",
        body: "Höchste Sicherheit durch zertifizierte Rechenzentren. SSL-verschlüsselte Übertragung aller Daten. Doppelt-verschlüsselte Zugangskennung."
      }
    ]
  }

  // footer section
  const footerSection = {
    locale: "de-DE",
    defaultLocale: "fr-FR",
    headerText:
      'Sie erreichen uns unter: <a href="tel:0481681090">+49 30 30808022</a>',
    menuItems: [
      {
        id: "3b738a7e-ba55-59c9-a80a-cdcf55e3f22d",
        title: "ÜBER UNS",
        subtitle: null,
        targetUrl: null,
        relAttribute: null,
        targetAttribute: null,
        displayStyle: null,
        backgroundColor: null,
        eventAction: "click_footer",
        eventLabel: "aboutus",
        icon: null,
        primaryColumnMenuItemIcon: null,
        ctaImage: null,
        ctaTitle: null,
        ctaLink: null,
        childMenuItems: [
          {
            id: "2b7ced3b-0535-5d32-bc3e-f30d7fc0358d",
            title: "Karriere",
            subtitle: null,
            targetUrl: "https://career.agicap.com/",
            relAttribute: "nofollow",
            targetAttribute: "blank",
            displayStyle: null,
            backgroundColor: null,
            eventAction: "click_footer",
            eventLabel: "aboutus-career",
            icon: null,
            primaryColumnMenuItemIcon: null,
            ctaImage: null,
            ctaTitle: null,
            ctaLink: null,
            childMenuItems: null
          },
          {
            id: "8a7fbba9-e766-5cdc-b6e6-1efe2d571eb1",
            title: "Kontakt",
            subtitle: null,
            targetUrl: "/de/kontakt-aufnehmen/",
            relAttribute: null,
            targetAttribute: null,
            displayStyle: null,
            backgroundColor: null,
            eventAction: "click_footer",
            eventLabel: "aboutus-contact",
            icon: null,
            primaryColumnMenuItemIcon: null,
            ctaImage: null,
            ctaTitle: null,
            ctaLink: null,
            childMenuItems: null
          },
          {
            id: "57d15409-f43a-52dc-9b72-48221c1e0ca1",
            title: "Partner",
            subtitle: null,
            targetUrl: "/de/partner-werden/",
            relAttribute: null,
            targetAttribute: null,
            displayStyle: null,
            backgroundColor: null,
            eventAction: "click_footer",
            eventLabel: "aboutus-partner",
            icon: null,
            primaryColumnMenuItemIcon: null,
            ctaImage: null,
            ctaTitle: null,
            ctaLink: null,
            childMenuItems: null
          },
          {
            id: "8fa08191-8153-5a67-a5f6-b222b883402f",
            title: "Presse",
            subtitle: null,
            targetUrl: "/de/presse/",
            relAttribute: null,
            targetAttribute: null,
            displayStyle: null,
            backgroundColor: null,
            eventAction: "click_footer",
            eventLabel: "aboutus-press",
            icon: null,
            primaryColumnMenuItemIcon: null,
            ctaImage: null,
            ctaTitle: null,
            ctaLink: null,
            childMenuItems: null
          },
          {
            id: "02c7277b-e711-5eec-a088-21a1f59d60c7",
            title: "Seitenverzeichnis",
            subtitle: null,
            targetUrl: "/de/seitenverzeichnis/",
            relAttribute: null,
            targetAttribute: null,
            displayStyle: null,
            backgroundColor: null,
            eventAction: "click_footer",
            eventLabel: "aboutus-sitemap",
            icon: null,
            primaryColumnMenuItemIcon: null,
            ctaImage: null,
            ctaTitle: null,
            ctaLink: null,
            childMenuItems: null
          }
        ]
      },
      {
        id: "824700cb-0c5a-5fc2-9c08-6ea707679758",
        title: "GESCHÄFTSSEKTOREN",
        subtitle: null,
        targetUrl: null,
        relAttribute: null,
        targetAttribute: null,
        displayStyle: null,
        backgroundColor: null,
        eventAction: "click_footer",
        eventLabel: "sectors",
        icon: null,
        primaryColumnMenuItemIcon: null,
        ctaImage: null,
        ctaTitle: null,
        ctaLink: null,
        childMenuItems: [
          {
            id: "b1e35092-2c44-59b8-b14d-cbcf0820b33b",
            title: "Industrie",
            subtitle: null,
            targetUrl: "/de/geschaftsektor/industrie/",
            relAttribute: null,
            targetAttribute: null,
            displayStyle: null,
            backgroundColor: null,
            eventAction: "click_footer",
            eventLabel: "sectors-industry",
            icon: null,
            primaryColumnMenuItemIcon: null,
            ctaImage: null,
            ctaTitle: null,
            ctaLink: null,
            childMenuItems: null
          },
          {
            id: "db676d71-3afb-5fa4-9fd4-3888ad399db3",
            title: "Gastronomie",
            subtitle: null,
            targetUrl: "/de/geschaftsektor/gastronomen/",
            relAttribute: null,
            targetAttribute: null,
            displayStyle: null,
            backgroundColor: null,
            eventAction: "click_footer",
            eventLabel: "sectors-catering",
            icon: null,
            primaryColumnMenuItemIcon: null,
            ctaImage: null,
            ctaTitle: null,
            ctaLink: null,
            childMenuItems: null
          },
          {
            id: "f8271bab-fa4a-522a-afed-d592d7651602",
            title: "Einzelhandel",
            subtitle: null,
            targetUrl: "/de/geschaftsektor/einzelhandel/",
            relAttribute: null,
            targetAttribute: null,
            displayStyle: null,
            backgroundColor: null,
            eventAction: "click_footer",
            eventLabel: "sectors-retail",
            icon: null,
            primaryColumnMenuItemIcon: null,
            ctaImage: null,
            ctaTitle: null,
            ctaLink: null,
            childMenuItems: null
          },
          {
            id: "f408a5e1-a3b2-5166-913d-5ac502c9125e",
            title: "Bauwesen",
            subtitle: null,
            targetUrl: "/de/geschaftsektor/bauwesen/",
            relAttribute: null,
            targetAttribute: null,
            displayStyle: null,
            backgroundColor: null,
            eventAction: "click_footer",
            eventLabel: "sectors-construction",
            icon: null,
            primaryColumnMenuItemIcon: null,
            ctaImage: null,
            ctaTitle: null,
            ctaLink: null,
            childMenuItems: null
          },
          {
            id: "16590f5e-6f73-5dff-a0ab-5bb1b8264ce6",
            title: "Immobilienbranche",
            subtitle: null,
            targetUrl: "/de/geschaftsektor/immobilien/",
            relAttribute: null,
            targetAttribute: null,
            displayStyle: null,
            backgroundColor: null,
            eventAction: "click_footer",
            eventLabel: "sectors-real-estate",
            icon: null,
            primaryColumnMenuItemIcon: null,
            ctaImage: null,
            ctaTitle: null,
            ctaLink: null,
            childMenuItems: null
          },
          {
            id: "53132cef-dd28-5a33-ad97-1d0cc4cc4785",
            title: "Agenturen ",
            subtitle: null,
            targetUrl: "/de/geschaftsektor/agenturen/",
            relAttribute: null,
            targetAttribute: null,
            displayStyle: null,
            backgroundColor: null,
            eventAction: "click_footer",
            eventLabel: "sectors-agencies",
            icon: null,
            primaryColumnMenuItemIcon: null,
            ctaImage: null,
            ctaTitle: null,
            ctaLink: null,
            childMenuItems: null
          }
        ]
      },
      {
        id: "9a4eb67e-b142-5781-9d22-3b0559ec7807",
        title: "RECHTLICHES",
        subtitle: null,
        targetUrl: null,
        relAttribute: null,
        targetAttribute: null,
        displayStyle: null,
        backgroundColor: null,
        eventAction: "click_footer",
        eventLabel: "legal",
        icon: null,
        primaryColumnMenuItemIcon: null,
        ctaImage: null,
        ctaTitle: null,
        ctaLink: null,
        childMenuItems: [
          {
            id: "084325d0-af70-54a4-adb4-6bdb3ff4dd5c",
            title: "Sicherheit",
            subtitle: null,
            targetUrl: "/de/sicherheit/",
            relAttribute: null,
            targetAttribute: null,
            displayStyle: null,
            backgroundColor: null,
            eventAction: "click_footer",
            eventLabel: "legal-security",
            icon: null,
            primaryColumnMenuItemIcon: null,
            ctaImage: null,
            ctaTitle: null,
            ctaLink: null,
            childMenuItems: null
          },
          {
            id: "33c81b81-7833-54a2-8ae0-919c8e03b312",
            title: "Allgemeine Nutzungsbedingungen",
            subtitle: null,
            targetUrl: "/de/allgemeine-nutzungsbedingungen/",
            relAttribute: null,
            targetAttribute: null,
            displayStyle: null,
            backgroundColor: null,
            eventAction: "click_footer",
            eventLabel: "legal-terms-of-use",
            icon: null,
            primaryColumnMenuItemIcon: null,
            ctaImage: null,
            ctaTitle: null,
            ctaLink: null,
            childMenuItems: null
          },
          {
            id: "619abf71-0cf0-5af2-9af4-544f968f1b55",
            title: " Allgemeine Geschäftsbedingungen",
            subtitle: null,
            targetUrl: "/de/agb/",
            relAttribute: null,
            targetAttribute: null,
            displayStyle: null,
            backgroundColor: null,
            eventAction: "click_footer",
            eventLabel: "legal-terms-conditions",
            icon: null,
            primaryColumnMenuItemIcon: null,
            ctaImage: null,
            ctaTitle: null,
            ctaLink: null,
            childMenuItems: null
          },
          {
            id: "27cfb6ea-249b-5fd4-a956-5a4e7ec7de3d",
            title: "Impressum",
            subtitle: null,
            targetUrl: "/de/impressum/",
            relAttribute: null,
            targetAttribute: null,
            displayStyle: null,
            backgroundColor: null,
            eventAction: "click_footer",
            eventLabel: "legal-notice",
            icon: null,
            primaryColumnMenuItemIcon: null,
            ctaImage: null,
            ctaTitle: null,
            ctaLink: null,
            childMenuItems: null
          },
          {
            id: "bd25c2a4-958f-52a6-a143-fa1c20d1adc5",
            title: "Datenschutzerklärung ",
            subtitle: null,
            targetUrl: "/de/datenschutzerklarung/",
            relAttribute: null,
            targetAttribute: null,
            displayStyle: null,
            backgroundColor: null,
            eventAction: "click_footer",
            eventLabel: "legal-privacy",
            icon: null,
            primaryColumnMenuItemIcon: null,
            ctaImage: null,
            ctaTitle: null,
            ctaLink: null,
            childMenuItems: null
          }
        ]
      },
      {
        id: "012c5408-560d-507e-97a2-0b566ca463b2",
        title: "Ressourcen",
        subtitle: null,
        targetUrl: null,
        relAttribute: null,
        targetAttribute: null,
        displayStyle: null,
        backgroundColor: null,
        eventAction: "click_footer",
        eventLabel: "ressources",
        icon: null,
        primaryColumnMenuItemIcon: null,
        ctaImage: null,
        ctaTitle: null,
        ctaLink: null,
        childMenuItems: [
          {
            id: "7a4e5774-cea2-54fe-9c61-e5c86b584385",
            title: "Kunden",
            subtitle: null,
            targetUrl: "https://agicap.com/de/kunden/",
            relAttribute: null,
            targetAttribute: null,
            displayStyle: null,
            backgroundColor: null,
            eventAction: "click_footer",
            eventLabel: "ressources-clients",
            icon: null,
            primaryColumnMenuItemIcon: null,
            ctaImage: null,
            ctaTitle: null,
            ctaLink: null,
            childMenuItems: null
          },
          {
            id: "e907a63a-963b-5b81-86af-13d0ca9df630",
            title: "Blog",
            subtitle: null,
            targetUrl: "/de/blog/",
            relAttribute: null,
            targetAttribute: null,
            displayStyle: null,
            backgroundColor: null,
            eventAction: "click_footer",
            eventLabel: "ressources-blog",
            icon: null,
            primaryColumnMenuItemIcon: null,
            ctaImage: null,
            ctaTitle: null,
            ctaLink: null,
            childMenuItems: null
          }
        ]
      }
    ]
  }

  const pageData = {
    topMenuSection,
    pageHeadSection,
    partnersSection,
    demoVideoSection,
    presentationItemsSection,
    boxedFeatures,
    demoFormSection,
    featuresSection,
    imageFeatures,
    videoTestimonialSection,
    gettingStartedSection,
    footerSection
  }

  const locale = "de-DE"
  const trackingData = getPageData(
    locale,
    {
      pagePerimeter: "landing_page",
      pagePublication: "",
      pageFeature: "cashflow_monitoring"
    },
    "n-finanzplanung",
    props.path
  )
  return (
    <Layout>
      <Helmet>
        <title>Kluge Finanzplanung für KMU, einfach automatisiert</title>
        <meta
          name="description"
          content="Ihre Finanzplanung automatisieren Sie einfach mit Agicap. Beobachten Sie mit dem Tool Ihre Liquidität in Echtzeit und erstellen Sie sichere Prognosen."
        />
        <meta name="robots" content="noindex nofollow" />
        <script type="text/javascript">{`${trackingData}`}</script>
      </Helmet>
      <NewBrandLP pageData={pageData} />
    </Layout>
  )
}

LogicielGestionFinanciere.propTypes = {
  data: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired
}
export default LogicielGestionFinanciere
